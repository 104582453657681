.hide {
  overflow: hidden;
  text-decoration: 'none';
  transition: height 0.5s ease;
}
.dna {
  text-decoration: 'none' !important;
  transition: height 0.5s ease;
  cursor: crosshair;

}

.dna:hover {
  cursor: crosshair;
  
}