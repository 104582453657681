.filter-title:hover .filter-title{
    opacity: 1 !important;
    cursor: crosshair !important;
    transition: opacity 500ms 1ms !important;
    transition-delay: 0s !important;
}

.active {
    transition: opacity 500ms 1ms !important;
    transition-delay: 0s !important;
    opacity: 1;
}