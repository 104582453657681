@import url("https://use.typekit.net/czg3nsi.css");

.dna:hover {
  opacity: 0.4;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dna {
  opacity: 1;
  text-decoration: 'none' !important;
  transition: all 0.3s ease;
}
a {
  text-decoration: 'none' !important;
}
.dnaHovered {
  opacity: 1;
  transition: all 0.3s ease;
  cursor: crosshair;
}

.rus {
  font-family: 'Rubik', sans-serif;
  font-weight: bold !important;

}