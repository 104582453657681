.mainImg {
    max-height: 68.5vh;
    object-fit: scale-down;
}

.mainImgPort {
    max-width: 29.7vw;
    max-height: 60.3vh;
}

.leftImg {
    max-width: 14.3vw;
    max-height: 26.6vh;
}
.subImg {
    max-width: 14.4vw;
    max-height: 17vh;
}
.mainLeftImg {
    max-width: 27vw;
    height: 60vh;
}
.mainImgRight {
    max-width: 27vw;
    height: 60vh;
}
.mainCenterImg {
    padding-top: 5vh;
    width: 27vw;
    max-height: 67vh;
    
}

@font-face {
    font-family: 'FrankG';
    src: url('../../assets/fonts/FrankG_Regular.otf');
    font-weight: normal;
    font-style: normal;
}
