@import url(https://use.typekit.net/czg3nsi.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rus-font {
  font-family: 'Rubik', sans-serif;
  font-weight: bold !important;
}
.dna:hover {
  opacity: 0.4;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dna {
  opacity: 1;
  -webkit-text-decoration: 'none' !important;
          text-decoration: 'none' !important;
  transition: all 0.3s ease;
}
a {
  -webkit-text-decoration: 'none' !important;
          text-decoration: 'none' !important;
}
.dnaHovered {
  opacity: 1;
  transition: all 0.3s ease;
  cursor: crosshair;
}

.rus {
  font-family: 'Rubik', sans-serif;
  font-weight: bold !important;

}
.hide {
  overflow: hidden;
  -webkit-text-decoration: 'none';
          text-decoration: 'none';
  transition: height 0.5s ease;
}
.dna {
  -webkit-text-decoration: 'none' !important;
          text-decoration: 'none' !important;
  transition: height 0.5s ease;
  cursor: crosshair;

}

.dna:hover {
  cursor: crosshair;
  
}
button {
    display: none !important;
}
.mainImg {
    max-height: 68.5vh;
    object-fit: scale-down;
}

.mainImgPort {
    max-width: 29.7vw;
    max-height: 60.3vh;
}

.leftImg {
    max-width: 14.3vw;
    max-height: 26.6vh;
}
.subImg {
    max-width: 14.4vw;
    max-height: 17vh;
}
.mainLeftImg {
    max-width: 27vw;
    height: 60vh;
}
.mainImgRight {
    max-width: 27vw;
    height: 60vh;
}
.mainCenterImg {
    padding-top: 5vh;
    width: 27vw;
    max-height: 67vh;
    
}

@font-face {
    font-family: 'FrankG';
    src: url(/static/media/FrankG_Regular.3912705e.otf);
    font-weight: normal;
    font-style: normal;
}

.filter-title:hover .filter-title{
    opacity: 1 !important;
    cursor: crosshair !important;
    transition: opacity 500ms 1ms !important;
    transition-delay: 0s !important;
}

.active {
    transition: opacity 500ms 1ms !important;
    transition-delay: 0s !important;
    opacity: 1;
}
@font-face {
    font-family: 'FrankG';
    src: url(/static/media/FrankG_Regular.3912705e.otf);
    font-weight: normal;
    font-style: normal;
}

.hoverMe:hover{
    opacity: 0.3 !important;
}
